
import BudgetsStatusService from "@/modules/budgets-status/budgets-status-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BudgetsStatusService.create(payload).then(response => {
                resolve({
                    message: "BudgetsStatus criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BudgetsStatus",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            BudgetsStatusService.update(payload.id,payload).then(response => {
                resolve({
                    message: "BudgetsStatus atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BudgetsStatus",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BudgetsStatusService.remove(id).then(response => {
                resolve({
                    message: "BudgetsStatus removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BudgetsStatus",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            BudgetsStatusService.delete(id).then(response => {
                resolve({
                    message: "BudgetsStatus deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BudgetsStatus",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            BudgetsStatusService.get(id).then(response => {
                resolve({
                    message: "BudgetsStatus encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsStatus",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BudgetsStatusService.paginate(query).then(response => {
                resolve({
                    message: "BudgetsStatus encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsStatus",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BudgetsStatusService.all().then(response => {
                resolve({
                    message: "BudgetsStatus encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsStatus",
                    success: false,
                    error
                });
            })
        })
    }
}
